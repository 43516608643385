Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiMethodType_get = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.apiMethodType_post = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "videoLibrary";
exports.labelBodyText = "videoLibrary Body";
exports.exampleVideoURL = "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"

exports.end_point_getAllTheme = "video_libraries/theme_listing"
exports.end_point_getVideosFromSelectedTheme = "video_libraries/theme_video"
exports.end_point_uploadVideo = "video_libraries"
exports.btnExampleTitle = "CLICK ME";
exports.contentRepostApiContentType = "application/json";
exports.contentFormData = "multipart/form-data"
exports.uploadSuccessfull = "Video uploaded successfully"
exports.ApiContentType = "application/json";
exports.EditApiContentType = "multipart/form-data";
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiDeleteMethod = "DELETE";
exports.apiPatchMethod = "PATCH";
exports.themesListAPiEndPoint = "bx_block_video_library/video_libraries/theme_listing";
exports.videoListAPiEndPoint = "bx_block_video_library/video_libraries/theme_video";
exports.uploadVideoAPiEndPoint = "bx_block_video_library/video_libraries"
// Customizable Area End