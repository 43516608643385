Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.exampleAPiMethod = "POST";
  exports.exampleApiContentType = "application/json";
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "ICalendarSupport";
  exports.labelBodyText = "ICalendarSupport Body";
  
  exports.btnExampleTitle = "CLICK ME";
  
  exports.eventHeading = "Event Details";
  exports.import = "Import";
  exports.date = "Date: ";
  exports.cancel = "Cancel";
  exports.export = "Export";
  exports.eventList = "Event Lists";
  exports.message = "Message";
  exports.successsMsg = "Event addes in your calendar";
  exports.delete = "Delete";
  exports.errorMessage = "Something went wrong!";
  exports.CALENDAR_EVENTS_STORAGE_KEY = "iCalendarEvents"
  // Customizable Area End