import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ICalendarLink from "react-icalendar-link";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {},
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ICalendarSupportController, {
  Props,
  configJSON,
} from "./ICalendarSupportController";

// Customizable Area Start
// Customizable Area End

export default class ICalendarSupport extends ICalendarSupportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <input
              data-test-id="uploadFile"
              id="uploadFile"
              type="file"
              onChange={this.loadFileWeb}
              accept="text/calendar"
              hidden
            />
            <label htmlFor="uploadFile">
              <Button
                style={webStyle.buttonStyle}
                color={"primary"}
                component="span"
              >
                {configJSON.import}
              </Button>
            </label>
            <div style={{ width: "100%" }}>
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                data-test-id="eventCalendar"
                eventContent={(eventInfo) => {
                  return (
                    <Box
                      style={webStyle.event}
                      data-test-id="eventItem"
                      onClick={() => this.eventClickHandlerWeb(eventInfo)}
                    >
                      <Typography style={webStyle.eventTitle}>
                        {eventInfo.event.title}
                      </Typography>
                    </Box>
                  );
                }}
                events={this.state.events}
              />
            </div>
          </Box>
        </Container>
        <Dialog
          open={this.state.selectedEvent !== null}
          onClose={this.closeModalHandle}
          data-test-id="eventModal"
        >
          <DialogTitle>{configJSON.eventHeading}</DialogTitle>
          <DialogContent>
            <Typography>
              <strong>{this.state.selectedEvent?.event.title}</strong>
            </Typography>
            <Typography style={webStyle.description}>
              {this.state.selectedEvent?.event?.extendedProps?.description}
            </Typography>
            <Typography style={webStyle.date}>
              {configJSON.date}{" "}
              {this.getDate(new Date(this.state.selectedEvent?.event.start))}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              style={webStyle.actionButton}
              data-test-id="deleteEvent"
              onClick={() => {
                this.deleteEvent(this.state.selectedEvent?.event?.id);
                this.closeModalHandle();
              }}
              color="primary"
              variant="contained"
              startIcon={<DeleteOutline />}
            >
              {configJSON.delete}
            </Button>
            <Button
              style={webStyle.actionButton}
              onClick={this.closeModalHandle}
              color="primary"
              variant="contained"
            >
              {configJSON.cancel}
            </Button>
            <ICalendarLink
              event={{
                title: this.state.selectedEvent?.event.title,
                description:
                  this.state.selectedEvent?.event?.extendedProps?.description,
                startTime: this.state.selectedEvent?.event.start,
                location:
                  this.state.selectedEvent?.event?.extendedProps?.location,
              }}
            >
              <Button
                style={webStyle.actionButton}
                color="primary"
                variant="contained"
              >
                {configJSON.export}
              </Button>
            </ICalendarLink>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },

  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  event: {
    backgroundColor: "#3f51b5",
    padding: 5,
    borderRadius: 4,
    width: "100%",
    overflow: "hidden",
    cursor: "pointer",
  },
  eventTitle: {
    color: "#fff",
    fontSize: 14,
  },
  modalEventTitle: {},
  description: {
    color: "#808080",
  },
  date: {
    color: "#808080",
  },
  iCalenderLink: {
    textDecoration: "none",
  },
  actionButton: {
    backgroundColor: "#3f51b5",
  },
};
// Customizable Area End
