import React from "react";
import {Redirect} from "react-router-dom";

import {
  Container,
  Button,
  Typography,
  FormControl, FormLabel, RadioGroup, Box
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f50057",
    },
    secondary:{
      main:'#2196f3',
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
      color: "#000",
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


// Customizable Area End

import PollingController, {
  Props,
  configJSON,
} from "./PollingController";

export default class Polling extends PollingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    if(!localStorage.getItem("LOGIN_TOKEN")){
      return <Redirect to='/email-account-login' />
    }
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <FormControl style={webStyle.mainWrapper}>
            {!this.state.isSubmitted ? 
            (<>
              <FormLabel>
              <Typography variant="h6"><b>{configJSON.Question}</b>  {this.state.question}</Typography>
              <Typography variant="body1" style={webStyle.hintText}>{configJSON.SelectOne}</Typography>
              </FormLabel>
              <RadioGroup>
                {
                  this.state.options.map((option: string, index: number) => {
                    const { background, color } = this.getBackgroundcolor(option);
                    return (
                    <div key={option.toString()}>
                      <Box style={webStyle.selectOption} data-test-id={`option-${option}`} onClick={() => this.state.isNext && this.handleOptionSelection(option)} >
                        <Typography style={{...webStyle.optionNum, background, color }}>{index + 1}</Typography>
                         {option}
                      </Box>                                                                                                                            
                    </div>);
                  })                                                                                                                                               
                }
              </RadioGroup>
              {this.state.showError && <Typography style={webStyle.errormsg}>{configJSON.errorText}</Typography>}
              <Box sx={webStyle.nextBtn}>
                <Button 
                  color="secondary"
                  variant="outlined"
                  data-test-id={`${this.state.isNext ? "btnNext": "btnSubmit"}`}
                  type="submit" 
                  onClick={() => this.state.isNext ? this.handleNext() : this.handleSubmit()} 
                  style={webStyle.buttonStyle}
                  disabled={this.state.isLoading}
                >
                  {this.state.isNext ? configJSON.btnNext  : configJSON.btnSubmit
                  }
                </Button>
              </Box>
              </>
            ) : (
              <Box sx={webStyle.resultStyle}>
                <Typography data-test-id="lblResult" variant="h5" align="center">{configJSON.Results}</Typography>
                <Box>
                  <Typography>{configJSON.answered} {this.state.rightAnswerCount} {configJSON.outOf}{this.state.pollListResponse.length} {configJSON.rightquestions}</Typography>
                </Box>
                <Button color="secondary" data-test-id="btnGoHome" variant="outlined" onClick={() => this.goHome()}>{configJSON.backHome}</Button>
              </Box>
            )}
            </FormControl>
          
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    paddingBottom: "30px",
    background: "#fff",
    justifyContent: "space-around",
    boxShadow: "rgb(202 202 202) 0px 0px 13px",
    padding: "30px",
    borderRadius: "10px",
    minHeight: "60vh"
  },
  inputStyle: {
    backgroundColor: "#f0f00b",
    position: "relative",
    
  },
  errormsg: {
    color: "rgb(254 4 4)",
    fontSize: "12px",
  },
  radiocircle: {
    display: "none",
  },
  formgroup: {
    position: "relative",
  },
  numberStyle: {
    position: "absolute",
    width: "25px",
    height: "25px",
    background: "#e3e3e5",
    top: "9px",
    borderRadius: "100%",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
   
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "#fff",
    maxWidth: "300px",
    borderRadius: "20px"
  },
  resultStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap:"12rem"
  },
  nextBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectOption: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
    alignItems: 'center',
    cursor: "pointer",
  },
  optionNum: {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    lineHeight: '30px',
  },
  hintText: {
    fontSize: '12px',
    lineHeight: '2.5'
  }
};
// Customizable Area End