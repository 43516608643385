Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "GoogleCalendarSync";
exports.labelBodyText = "GoogleCalendarSync Body";

exports.btnExampleTitle = "CLICK ME";

exports.syncWithGoogleCalendar = "Synch With Google Calendar";
exports.cancelText = "Close";
exports.guestText = "Guests";
exports.addEventText = "Add Event";
exports.saveText = "Create";
exports.successMsg = "Event created!";
exports.errorMsg = "Something went wrong! Please try again...";
exports.signOut = "Signout";

exports.summaryPlaceholder = "Summary";
exports.startDatePlaceholder = "Start Date";
exports.endDatePlaceholder = "End Date";
exports.locationPlaceholder = "Location";
exports.descriptionPlaceholder = "Description";
exports.attendeesPlaceholder = "Attendees Email Id";
exports.attendeesHelperText = "Enter attendeed email with separated by common";
exports.emptyEventTitle = "Event";

exports.dateFormat = "YYYY-MM-DD HH:mm";
exports.calendarDateFormat = "YYYY-MM-DD";

exports.googleEventEntPoint =
  "https://www.googleapis.com/calendar/v3/calendars/primary/events";
exports.googleEventApiContentType = "application/json";
exports.googleCalendarIOSClientId =
  "361556230615-qjsug9hpjg8dehfq5tkkmfvhg313letf.apps.googleusercontent.com";
exports.googleCalendarAndroidClientId =
  "855520919327-cktsoaac70dtudhartgir8l26337lki1.apps.googleusercontent.com";
exports.googleCalendarClientId =
  "361556230615-4b20dc8e74m60uqpm8vb8m477fg768lu.apps.googleusercontent.com";
exports.googleCalendarScope = "https://www.googleapis.com/auth/calendar";
exports.getEventApiMethod = "GET";
exports.addEventApiMethod = "POST";
// Customizable Area End
