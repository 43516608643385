import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import AnalyticsAdapter from "../../blocks/adapters/src/AnalyticsAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import SocialMediaAccountLoginAdapter from "../../blocks/adapters/src/SocialMediaAccountLoginAdapter";
import OnboardingAdapter from "../../blocks/adapters/src/OnboardingAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";

//Assembler generated adapters start
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const analyticsAdapter = new AnalyticsAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const socialMediaAccountLoginAdapter = new SocialMediaAccountLoginAdapter();
const onboardingAdapter = new OnboardingAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to MentalHealthApp!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'Scheduling'}  onPress={() => navigation.navigate("Scheduling")} />
<CustomTextItem content={'Share'}  onPress={() => navigation.navigate("Share")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'InvoiceBilling'}  onPress={() => navigation.navigate("InvoiceBilling")} />
<CustomTextItem content={'utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'EmailAccountLoginBlock'}  onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'SocialMediaAccountLogin'}  onPress={() => navigation.navigate("SocialMediaAccountLogin")} />
<CustomTextItem content={'Pushnotifications'}  onPress={() => navigation.navigate("Pushnotifications")} />
<CustomTextItem content={'MobileAccountLoginBlock'}  onPress={() => navigation.navigate("MobileAccountLoginBlock")} />
<CustomTextItem content={'DailyScheduleNotifications'}  onPress={() => navigation.navigate("DailyScheduleNotifications")} />
<CustomTextItem content={'smssettings'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'crm'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'dashboard'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'GroupVideoCall'}  onPress={() => navigation.navigate("GroupVideoCall")} />
<CustomTextItem content={'Notificationsettings'}  onPress={() => navigation.navigate("Notificationsettings")} />
<CustomTextItem content={'Onboardingguide'}  onPress={() => navigation.navigate("Onboardingguide")} />
<CustomTextItem content={'productdescription'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'fileattachment'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'automaticreminders'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'VideoLibrary'}  onPress={() => navigation.navigate("VideoLibrary")} />
<CustomTextItem content={'docusignintegration'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'PhoneNumberInput'}  onPress={() => navigation.navigate("PhoneNumberInput")} />
<CustomTextItem content={'Gamification'}  onPress={() => navigation.navigate("Gamification")} />
<CustomTextItem content={'StripePayments'}  onPress={() => navigation.navigate("StripePayments")} />
<CustomTextItem content={'storecredits'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'surveys'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'AdvancedSearch'}  onPress={() => navigation.navigate("AdvancedSearch")} />
<CustomTextItem content={'audiolibrary'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'customisableuserprofiles'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Notifications'}  onPress={() => navigation.navigate("Notifications")} />
<CustomTextItem content={'livestreaming'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Reviews'}  onPress={() => navigation.navigate("Reviews")} />
<CustomTextItem content={'Videos'}  onPress={() => navigation.navigate("Videos")} />
<CustomTextItem content={'AccountGroups'}  onPress={() => navigation.navigate("AccountGroups")} />
<CustomTextItem content={'ShoppingCartOrders'}  onPress={() => navigation.navigate("ShoppingCartOrders")} />
<CustomTextItem content={'OrderManagement'}  onPress={() => navigation.navigate("OrderManagement")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'Comment'}  onPress={() => navigation.navigate("Comment")} />
<CustomTextItem content={'PostCreation'}  onPress={() => navigation.navigate("PostCreation")} />
<CustomTextItem content={'VisualAnalytics'}  onPress={() => navigation.navigate("VisualAnalytics")} />
<CustomTextItem content={'DesktopNotifications'}  onPress={() => navigation.navigate("DesktopNotifications")} />
<CustomTextItem content={'Analytics'}  onPress={() => navigation.navigate("Analytics")} />
<CustomTextItem content={'Appointments'}  onPress={() => navigation.navigate("Appointments")} />
<CustomTextItem content={'customform'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Customisableusersubscriptions'}  onPress={() => navigation.navigate("Customisableusersubscriptions")} />
<CustomTextItem content={'Chat'}  onPress={() => navigation.navigate("Chat")} />
<CustomTextItem content={'ReviewApprovalAdmin'}  onPress={() => navigation.navigate("ReviewApprovalAdmin")} />
<CustomTextItem content={'LanguageSupport'}  onPress={() => navigation.navigate("LanguageSupport")} />
<CustomTextItem content={'LanguageOptions'}  onPress={() => navigation.navigate("LanguageOptions")} />
<CustomTextItem content={'quickbooksintegration'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Splashscreen'}  onPress={() => navigation.navigate("Splashscreen")} />
<CustomTextItem content={'hamburgermenu'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'chatbot'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Contactus'}  onPress={() => navigation.navigate("Contactus")} />
<CustomTextItem content={'Maps'}  onPress={() => navigation.navigate("Maps")} />
<CustomTextItem content={'LandingPage'}  onPress={() => navigation.navigate("LandingPage")} />
<CustomTextItem content={'TermsConditions'}  onPress={() => navigation.navigate("TermsConditions")} />
<CustomTextItem content={'RecommendationEngine'}  onPress={() => navigation.navigate("RecommendationEngine")} />
<CustomTextItem content={'timetrackingbilling'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'timetracker'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Tasks'}  onPress={() => navigation.navigate("Tasks")} />
<CustomTextItem content={'GoogleCalendarSync'}  onPress={() => navigation.navigate("GoogleCalendarSync")} />
<CustomTextItem content={'contentmanagement'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'multiselect'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'DragDropInterface'}  onPress={() => navigation.navigate("DragDropInterface")} />
<CustomTextItem content={'repeatingtasks'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'ocropticalcharacterrecognition'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'ApiIntegration'}  onPress={() => navigation.navigate("ApiIntegration")} />
<CustomTextItem content={'CameraAccess'}  onPress={() => navigation.navigate("CameraAccess")} />
<CustomTextItem content={'SaveAsPdf'}  onPress={() => navigation.navigate("SaveAsPdf")} />
<CustomTextItem content={'outlookintegration'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'inapppurchasing'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Polling'}  onPress={() => navigation.navigate("Polling")} />
<CustomTextItem content={'ICalendarSupport'}  onPress={() => navigation.navigate("ICalendarSupport")} />
<CustomTextItem content={'RequestManagement'}  onPress={() => navigation.navigate("RequestManagement")} />
<CustomTextItem content={'Adminconsole2'}  onPress={() => navigation.navigate("Adminconsole2")} />
<CustomTextItem content={'Rolesandpermissions2'}  onPress={() => navigation.navigate("Rolesandpermissions2")} />
<CustomTextItem content={'Uploadmedia3'}  onPress={() => navigation.navigate("Uploadmedia3")} />
<CustomTextItem content={'Emailnotifications2'}  onPress={() => navigation.navigate("Emailnotifications2")} />
<CustomTextItem content={'Cfchangehealthcareadonnisapi'}  onPress={() => navigation.navigate("Cfchangehealthcareadonnisapi")} />
<CustomTextItem content={'Settings2'}  onPress={() => navigation.navigate("Settings2")} />
<CustomTextItem content={'Cfcoursemodules'}  onPress={() => navigation.navigate("Cfcoursemodules")} />
<CustomTextItem content={'Splitpayments2'}  onPress={() => navigation.navigate("Splitpayments2")} />
<CustomTextItem content={'Cfactivityconsole2'}  onPress={() => navigation.navigate("Cfactivityconsole2")} />
<CustomTextItem content={'Cfaccesslevelmatrix2'}  onPress={() => navigation.navigate("Cfaccesslevelmatrix2")} />
<CustomTextItem content={'Cfattendancetracking2'}  onPress={() => navigation.navigate("Cfattendancetracking2")} />
<CustomTextItem content={'Cfautomateddataencryption2'}  onPress={() => navigation.navigate("Cfautomateddataencryption2")} />
<CustomTextItem content={'Cfbillingcodeoverridestacking2'}  onPress={() => navigation.navigate("Cfbillingcodeoverridestacking2")} />
<CustomTextItem content={'Cfbrandedinterfacedocuments2'}  onPress={() => navigation.navigate("Cfbrandedinterfacedocuments2")} />
<CustomTextItem content={'Cfbrandedsingupportal2'}  onPress={() => navigation.navigate("Cfbrandedsingupportal2")} />
<CustomTextItem content={'Cfbulkbillingsuperinvoices2'}  onPress={() => navigation.navigate("Cfbulkbillingsuperinvoices2")} />
<CustomTextItem content={'Cfcallqueueing2'}  onPress={() => navigation.navigate("Cfcallqueueing2")} />
<CustomTextItem content={'Cfclickupintegration2'}  onPress={() => navigation.navigate("Cfclickupintegration2")} />
<CustomTextItem content={'Cfnonstandardinfrasetup2'}  onPress={() => navigation.navigate("Cfnonstandardinfrasetup2")} />
<CustomTextItem content={'Cfcloudflaredomainmapping2'}  onPress={() => navigation.navigate("Cfcloudflaredomainmapping2")} />
<CustomTextItem content={'Cfcustomnotetemplatescreator2'}  onPress={() => navigation.navigate("Cfcustomnotetemplatescreator2")} />
<CustomTextItem content={'Cfcustomonboardingflowcreator2'}  onPress={() => navigation.navigate("Cfcustomonboardingflowcreator2")} />
<CustomTextItem content={'Cfcustomroleandpermissioncreator2'}  onPress={() => navigation.navigate("Cfcustomroleandpermissioncreator2")} />
<CustomTextItem content={'Cfdirectbilling2'}  onPress={() => navigation.navigate("Cfdirectbilling2")} />
<CustomTextItem content={'Cfdisablescreenshots4'}  onPress={() => navigation.navigate("Cfdisablescreenshots4")} />
<CustomTextItem content={'Cfmasksensitivefields2'}  onPress={() => navigation.navigate("Cfmasksensitivefields2")} />
<CustomTextItem content={'Cfmicrosoftdefenderforcloud5'}  onPress={() => navigation.navigate("Cfmicrosoftdefenderforcloud5")} />
<CustomTextItem content={'Cfonboardingjumpin2'}  onPress={() => navigation.navigate("Cfonboardingjumpin2")} />
<CustomTextItem content={'Cfonfidointegration2'}  onPress={() => navigation.navigate("Cfonfidointegration2")} />
<CustomTextItem content={'Cfringfencepatients2'}  onPress={() => navigation.navigate("Cfringfencepatients2")} />
<CustomTextItem content={'Cfringfencetherapists2'}  onPress={() => navigation.navigate("Cfringfencetherapists2")} />
<CustomTextItem content={'Cfwhitelabelurl2'}  onPress={() => navigation.navigate("Cfwhitelabelurl2")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;