import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  items: Array<string>;
  item: string;
  isEditing: boolean;
  temp_id: any;
  formValidate: boolean;
  data: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DragDropInterfaceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      item: "",
      items: [],
      isEditing: false,
      temp_id: null,
      formValidate: false,
      data: [...Array(10)].map((d, index) => ({
        key: `item-${index}`,
        label: index,
        backgroundColor: `rgb(${Math.floor(Math.random() * 255)}, ${
          index * 5
        }, ${132})`,
      })),
      // Customizable Area End
    };

    // Customizable Area Start
    this.add = this.add.bind(this);
    this.delete = this.delete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start

  add(e: any) {
    e.preventDefault();
    if (
      this.state.item != null &&
      this.state.item.trim() != "" &&
      this.state.item != undefined
    ) {
      let items = this.state.items;
      items.push(this.state.item);
      console.log(items);
      this.setState({ items: items, item: "", formValidate: false });
      localStorage.setItem("DragDropList", JSON.stringify(items));
    } else {
      this.setState({
        formValidate: true,
      });
    }
  }

  edit(id: any) {
    let item = this.state.items[id];
    this.setState({
      isEditing: true,
      item: item,
      temp_id: id,
      formValidate: false,
    });
  }

  update(e: any) {
    e.preventDefault();
    if (
      this.state.item != null &&
      this.state.item != "" &&
      this.state.item != undefined
    ) {
      let items = this.state.items;
      if (this.state.temp_id != null) {
        items[this.state.temp_id] = this.state.item;
      }
      this.setState({
        items: items,
        item: "",
        isEditing: false,
        temp_id: null,
        formValidate: false,
      });
      localStorage.setItem("DragDropList", JSON.stringify(items));
    } else {
      this.setState({
        formValidate: true,
      });
    }
  }

  delete(id: any) {
    let items = this.state.items;
    items.splice(id, 1);
    this.setState({
      items: items,
      item: "",
      isEditing: false,
      formValidate: false,
    });
    localStorage.setItem("DragDropList", JSON.stringify(items));
  }

  handleChange(event: any) {
    const name = event.target.name;
    const value: string = event.target.value;
    let item = this.state.item;
    item = value;
    this.setState({ item: item });
  }

  reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: "#fff",
      boxShadow: "5px 5px 5px #aaaaaa",
      borderRadius: "3px",
    }),
  });

  getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? "#E7EBF0" : "#E7EBF0",
    minWidth: "600px",
    borderRadius: "5px",
  });

  onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    const items: any = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
    localStorage.setItem("DragDropList", JSON.stringify(items));
  }
  // Customizable Area End
}
