Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.txtInputPlaceholder = "Enter Text";
exports.labelTitleText = "Polling";
exports.labelBodyText = "Polling Body";
exports.errorText = "Please select an option.";
exports.apiError = "There is technical glich, please try again after some time.";
exports.Question = "Question:";
exports.SelectOne = "Plese Select at least one option*"
exports.Results = "Results";
exports.answered = "you answered";
exports.outOf = "out of";
exports.rightquestions = "questions right";
exports.backHome = "Go back home"

exports.apiEndPointGetPollingList = "bx_block_polling/quiz_and_answer_pollings/get_question_lists";
exports.apiEndPointSubmitPoll = "bx_block_polling/quiz_and_answer_pollings/confirm_and_reset";
exports.contentType = "application/json";
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";

exports.btnSubmit = "Submit";
exports.btnNext = "Next";
exports.mockQuestionList = [
    {
        "id": "1",
        "type": "quiz_and_answer_polling",
        "attributes": {
            "title": "test",
            "question": "How tall was the largest bottle of wine on record?",
            "option_1": "2 m",
            "option_2": "3 m",
            "option_3": "4 m",
            "option_4": "5 m",
            "is_correct": [
                "3 m",
            ],
            "question_type": "radio_button",
            "total_count": 1,
            "user_count": 1,
            "grade_count": 1
        }
    },
    {
        "id": "21",
        "type": "quiz_and_answer_polling",
        "attributes": {
            "title": "test",
            "question": "In which country was the largest wine-tasting event held?",
            "option_1": "Portugal",
            "option_2": "Australia",
            "option_3": "Spain",
            "option_4": "Greece",
            "is_correct": [
                "Greece","Spain",
            ],
            "question_type": "multiple_choice",
            "total_count": 1,
            "user_count": 1,
            "grade_count": 1
        }
    }
];
// Customizable Area End